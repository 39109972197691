// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

    urlBetaIzex: "https://apl.beta-izex.ru/api/",
    keyBetaIzex: "88956aba576362ca65e2d1fcd3e9295a",

    production: false,
    default_lang: 'KR',

    comId: 'ALLRENU',
    comCd: '07',

    // apiUrl: 'http://localhost:8100/api',
    apiUrl: 'https://global.wownet.biz/api',
    urlWownet: "https://global.wownet.biz"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
