import { ValidationUtil } from '@app/common/util/validation.util';
import { environment } from '@enviroments/environment';
import { LangConstant } from '../constant/lang.constant';
import { AuthUtil } from './auth.util';
import { ConvertUtil } from './convert.util';
import { CommonConstant } from '../constant/common.constant';

export class CommonUtils {
    static getMessages(): string {
        return 'messages.' + CommonUtils.getLangMessage();
    }

    static getLangMessage(): string {
        let res = environment.default_lang.toLowerCase();
        let lang = CommonUtils.getLang();

        switch (lang) {
            case 'kr':
                res = 'kr';
                break;
            case 'en':
                res = 'en';
                break;
            case 'us':
                res = 'en';
                break;
            default:
                res = 'en';
                break;
        }

        return res;
    }

    static getLang(): string {
        let loginedInfo = AuthUtil.getLoginedInfo();
        let lang = sessionStorage.getItem(LangConstant.STR_LANG);

        if (ValidationUtil.isNullOrEmpty(lang)) {
            if (loginedInfo) {
                lang = ConvertUtil.convertToString(loginedInfo.ctrCd);
            } else {
                lang = environment.default_lang;
            }
            sessionStorage.setItem(LangConstant.STR_LANG, lang);
        }

        return ConvertUtil.convertToString(lang).toLowerCase();
    }

    static getYN(value: string) {
		return (value === null || value === CommonConstant.NO || value === undefined || value.length <= 0) ? false : true;
	}

    static getDateNow() {
		let currentDate = new Date();

		const y = currentDate.getFullYear();
		const m = currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1;
		const d = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();

		return ConvertUtil.convertToString('' + y + m + d);
	}

}

